import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import IframeLayout from '../components/iframe-layout/iframe-layout';
import IframePromo from '../components/iframe-promo/iframe-promo';

const ssRecreationPromo = ({ data }) => {
  // Pull out the relevant content from the data.
  // Make sure at least one result is returned before rendering the layout.
  const content = data.allTaxonomyTermPromotion.edges[0].node.relationships
    ? data.allTaxonomyTermPromotion.edges[0].node.relationships
      .field_promoted_content[0]
    : null;

  return (
    <React.Fragment>
      {content && (
        <IframeLayout>
          <IframePromo content={content} />
        </IframeLayout>
      )}
    </React.Fragment>
  );
};

ssRecreationPromo.propTypes = {
  data: PropTypes.object
};

export default ssRecreationPromo;

export const query = graphql`
  query ssRecreation {
    allTaxonomyTermPromotion(filter: { drupal_id: { eq: "ac480462-81dd-45b2-b5b1-be23a5869aaa" } }) {
      edges {
        node {
          name
          relationships {
            field_promoted_content {
              ...IframeEvent
              ...IframeNews
              ...IframePlace
            }
          }
        }
      }
    }
  }
`;
